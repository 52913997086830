@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Averia Serif Libre", serif;
}

*::-webkit-scrollbar {
  display: none !important;

}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
}

:root {
  --primary-color: #18203e;
  --input-border: #CCCCCC;
  --login-button-background: #FFBF01;
  --forgotpassowrd: #2D2D2D;
  --body-background-color: #8888;
  --nav-heading-color: #DAF5E6;
  --editButton: #def5e6;
  --deleteButton: #ffdede;
  --editText: #3ac977;
  --deleteText: #ff7e7e;
  --searchBarBackground: black;
  --chooseCompany: #00FFFF;
  --invoicesText: #0070FF;
}



body {
  background-color: var(--body-background-color);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}


.loginpage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
}

.loginScreen {
  background-color: var(--primary-color);
  height: auto;
  padding: 20px 50px;
  border-radius: 30px;
  padding-bottom: 100px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContent h3 {
  font-size: 20px;
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
}

.loginContent p {
  text-align: center;
  margin: 20px 0;
}

.loginInput {
  margin: 40px 0;
  color: white;
}

.loginInputs p {
  text-align: left;
  margin: 10px 0;
}

.loginInputs input {
  border-radius: 5px;
  border: 2px solid var(--input-border);
  outline: none;
  width: 99%;
  padding: 10px;
  color: black;
}

.loginButton {
  background-color: var(--login-button-background);
  width: 100%;
  color: black;
  border: none;
  padding: 12px 16px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 20PX;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.forgotPassword {
  margin-top: 20px;
  text-align: right;
  padding: 20px;

  margin-top: -55px;
  margin-bottom: 30px;
  margin-right: -20px;
}

.forgotPasswordLink {
  color: var(--forgotpassowrd);

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
  padding: 20px 20px;
  font-size: 20px;
  height: 60px;
}

.logoutButton {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  color: var(--login-button-background);
  text-decoration: none;
}

.sidenavbar {
  width: 250px;
  height: 100vh;
  background-color: white;
}

.sidenavbarClose {
  position: absolute;
  width: 250px;
  height: 100vh;
  background-color: white;
  margin: 0 -130px;
  left: -200px;
}

.sidenavbar ul {
  list-style: none;
}

.sideNavbarLink {
  text-decoration: none;
  color: inherit;
}

.sideNavItem {
  padding: 15px;
}

.selectedSideNavItem {
  color: white;
  background-color: var(--primary-color);
  padding: 15px;
}

.companyDetailsection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 1;
  position: relative;

}

.companyDetailsectionSmall {
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 1;

}

.companyDetailsProperty {
  background-color: white;
  height: 83vh;
  border-radius: 8px;
  border: 2px solid rgba(128, 128, 128, 0.438);
  margin-top: 20px;
}

.invoicesList {
  margin-top: 10px;

  height: 93vh;
  width: 85vw;

  overflow-x: hidden;
  /* position : relative; */

}

.companyDetailsNavbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

}

.addCompanyButton {
  background-color: var(--login-button-background);
  border: none;
  padding: 10px;
  font-size: 14px;
  font-size: 16px;
  border-radius: 4px;
}

.companyHeadingNav {
  width: 100%;
  height: 75%;
  overflow-y: scroll;
}

.companyHeadingBody {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 12px 0;
}

.companyHeadingBodyNew {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 12px 0;
}

.companyHeadingBodySmall {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 12px 0;
}

.mainData {
  border-bottom: 1px solid var(--body-background-color);
}

.companyNav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  background-color: var(--nav-heading-color);
  padding: 8px 20px;
  text-align: center;
}

.companyNavNew {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  background-color: var(--nav-heading-color);
  padding: 8px 20px;
  text-align: center;

}

.companyNavSmall {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background-color: var(--nav-heading-color);
  padding: 8px 20px;
  text-align: center;


}

.editButtons {}

.editButtons button {
  margin: 2px 12px;
  border: none;
  padding: 6px 20px;
  border-radius: 2px;

}

.shiftRightButton {
  width: 100%;
  justify-content: right;
}

.pagination {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
}

.searchBar {
  position: fixed;
  top: 11px;
  display: flex;
  left: 300px;
  border-radius: 2px;
  padding: 6px;
}

.searchBar2 {
  position: fixed;
  top: 11px;
  display: flex;
  left: 200px;
  border-radius: 2px;
  padding: 6px;

}

.searchBarInput {
  border: none;
  outline: none;
  height: 100%;
  padding: 5px 2px;
  width: 170px;
  background-color: var(--buttonBackgroundColor);
  border-radius: 2px;
  font-size: 12px;
}

.searchBarInput:focus {
  outline: none !important;
  border: none !important;
  color: white;
}

.searchBarInput {
  border: none !important;
}

.searchBar {

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--searchBarBackground);
}

.searchBar2 {

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--searchBarBackground);
  right: 15px;
  font-size: 18px;
  width: 150px;
}

.menuIcon2 {
  color: white;
  font-size: 25px;
  padding: 2px;
  left: 20px;

}

.chooseCompany {
  display: flex;
  width: fit-content;
  background-color: var(--primary-color);
  color: white;
  padding: 10px 10px;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;

}

.chooseCompany select {
  background-color: transparent;
  border: none;
  color: var(--chooseCompany);
  padding: 5px 0;
  font-size: 16px;

}

.chooseCompany label {
  background-color: transparent;
  border: none;
  margin: 0 20px;
}

.invoiceBottomPart {
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.invoiceBottomPartOne,
.invoiceBottomPartTwo {
  width: 47%;
  background-color: white;
  height: 80vh;
  position: relative;
}

.invoiceBottomPartOneSmall,
.invoiceBottomPartTwoSmall {
  width: 100%;
  background-color: white;
  height: 65vh;
  position: relative;
}

.invoiceBottomPartOne h2,
.invoiceBottomPartTwo h2 {
  padding: 10px 20px;

}

.manageInvoices {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.manageInvoices div {
  display: flex;
  justify-content: space-evenly;
  text-align: center;

}

.manageInvoicesItem {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
}

.manageInvoicesItem div {

  width: 100%;
  text-align: center;
}

.manageInvoicesItem button {
  background-color: transparent;
  color: var(--invoicesText);
  border: none;

}

.manageInvoiceCard {
  /* border : 1px solid blue; */
  width: 50%;
}

.manageInvoices div {
  width: 50%;
  text-align: center;
}

.manageInvoicesHeading {
  background-color: var(--nav-heading-color);
  padding: 5px;
  margin-top: 12px;
}

.manageInvoicesHeadingOne {
  background-color: var(--nav-heading-color);
  padding: 5px;
  margin-top: 12px;
  width: 100%;
  text-align: center;
}

.paginationInvoices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 99%;
  margin: auto;
  padding: 5px 5px;
}

.invoiceDetails {
  border: 1px solid gray;
  border-radius: 8px;
  margin-right: 30px;
  /* padding : 15px; */
  font-size: 15px;
  padding: 11px 0;
  font-size: 10px;

}

.invoiceDetailsSmall {
  /* border : 1px solid red; */
  border-radius: 8px;
  margin-right: 12px;

  /* padding : 15px; */
  font-size: 15px;
  padding: 6px 0;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.invoiceDetailsSmall {
  border: 1px solid gray;
  border-radius: 8px;
  margin-left: 10px;
  font-size: 12px;
  width: 100%;
}


.invoiceDetailsEntry {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
}

.invoiceDetailsEntry div {

  margin: 0 30px;

}

.invoiceStatus {
  background-color: var(--login-button-background);
  padding: 4px 12px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoiceDetailsUpperSection {
  background-color: white;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 20px;
}

.invoiceDetailsUpperSectionSmall {
  background-color: white;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.invoiceDetailsBottomSection {
  background-color: white;
  justify-content: space-between;
  display: flex;
  /* align-items: center; */
  padding: 20px;
  margin: 20px 0;
}

.invoiceDetailsBottomSectionSmall {
  background-color: white;
  justify-content: space-between;
  display: flex;
  /* align-items: center; */
  padding: 20px;
  margin: 20px 0;
  width: 99%;
  flex-direction: column;
}

.invoiceDetailsBottomSectionLeft {
  width: 48%;
  height: 55vh;
}

.invoiceDetailsBottomSectionLeftSmall {
  width: 95%;
  height: 55vh;
}

.invoiceInput {
  border: 1px solid gray;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
}

.invoiceLabel {
  margin-top: 3px;
  margin-bottom: 1px;
}

.invoiceDetailsBottomSectionRight {
  width: 50%;
  height: 100%;
  margin-left: 80px;
}

.invoiceDetailsBottomSectionRight {
  width: 100%;
  height: 100%;
}

.invoiceButton {
  padding: 7px 25px;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  border: none;
  background-color: var(--primary-color);
}

.invoiceButtonSmall {
  padding: 7px 25px;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  border: none;
  background-color: var(--primary-color);
  width: 96%;
}

.checkIcon {
  color: var(--login-button-background);
  background-color: var(--primary-color);
  border-radius: 50%;
  font-size: 27px;
  margin: 0 15px;
}

.landingButton {
  margin: 10px 0;
}

.landingButton div {
  margin: 5px 0;
}

.invoiceNumber {
  background-color: var(--primary-color);
  color: white;
  border-radius: 0;
  border: 2px solid var(--primary-color);
}

.styled-table {
  width: 50%;

}

.styled-table-small {
  width: 99%;
  margin-bottom: 7px;

}

.styled-table2 {
  width: 100%;
  overflow-y: scroll;
  height: 85%;
}

.manageInvoicesItemSelected div {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.manageInvoicesItemSelected {

  width: 100%;
  background-color: var(--primary-color);
  padding: 15px 5px;
  color: white;
}

.editButton {
  background-color: var(--editButton) !important;
  color: var(--editText) !important;
  border: none;
}

.deleteButton {
  background-color: var(--deleteButton);
  color: var(--deleteText);
  border: none;
}

.buttonsInvoice {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border : 1px solid red; */
  width: 50%;

}

.viewButton {
  background-color: #006eff55 !important;
  color: var(--invoicesText) !important;
}

.viewButton,
.commentButton,
.pendingButton {

  margin: 5px 10px !important;
  border: none !important;
  padding: 6px 20px !important;
  border-radius: 2px !important;
}

.commentButton {

  background-color: var(--deleteButton) !important;
  color: var(--deleteText) !important;
}

.pendingButton {
  background-color: var(--editButton) !important;
  color: var(--editText) !important;
}

@media (max-width: 1170px) {

  /* Your CSS rules here */
  body {
    background-color: white;
  }
}

/* .ant-pagination-item .ant-pagination-item-1 .ant-pagination-item-active{
  
} */
.ant-pagination-item-active {

  background-color: var(--login-button-background) !important;
  border: none !important;
  border-radius: 0px !important;
  color: black !important;

}

.ant-pagination-item-active a {

  color: black !important;

}

.ant-pagination {
  font-size: 14px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.ant-pagination-item {
  margin: -8px;
}

.ant-modal-content {
  width: 130%!important;
}

.shiftRightButton {
  padding-bottom: 10px;
}

.hamBurgorMenu {
  position: fixed;
  top: 11px;
  left: 250px;
  padding: 10px;
  border: none;
  background-color: black;
  color: white;
  font-size: 18px;
}

.hamBurgorMenu2 {
  position: fixed;
  top: 11px;
  left: 150px;
  padding: 10px;
  border: none;
  background-color: black;
  color: white;
  font-size: 18px;
}

.inputLabel {
  margin-bottom: 5px;
}

.addModeInput {
  display: flex;

}

.addModeInput div {
  margin-left: 2px;
  margin-right: 16px;
  width: 50%;
}

.popUpFooter {

  display: flex;
  justify-content: left;
}

.saveButton {
  color: black;
  background-color: var(--login-button-background);
  margin-right: 10px;
}

.popupFooter {
  margin-top: 30px;
}

.selectButton {
  border: none;

}

.selectButton select {
  padding: 7px;

}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    color: var(--primary-color);
    background-color: white;
}


.changePassword{
    display : flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap : 10px;
}
.changePassword h3{
    text-align: center;
    font-size: 30px;
}
.changePassword p{
    text-align: center;
font-size: 16px;
}
.button {
    background-color: #FF5E5E;
    color : white;
    width : 100%;
    height: 44px;
    border : none;
    border : 2px solid #ff5e5e;
    border-radius : 6px;
    font-weight: bold;
    font-size: 20px;
}


.changePasswordInput{
    height : 142.5px;
    margin : 20px 0;
}
.innerBox{ 
    width :100%;
}

.changePasswordInput p {
  font-weight: bold;
}
.logo {
    display: flex;
        justify-content: center;
        align-items: center;    
}
.logo img{
   width : 200px;
   margin-bottom: 20px;    
}

.checkCircle{
    display: flex;
    justify-content: center;
    align-items: center;
    color : green!important;
    font-size: 80px;
    margin :  20px 0;
}

.container2{
    height : 400px;
        color: #447EBF;
}